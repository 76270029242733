import React, { useMemo, cloneElement, useCallback } from 'react';
import classnames from 'classnames';

import EmptySectionBlock from 'components/Globals/EmptySectionBlock';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import classes from './Content.module.scss';

export const THEMES = {
  DARK: 'dark',
};

const ColumnLayout = ({ entityType, columns = 1, children, reverse, equalColumnWidth }) => (
  <div
    className={classnames(classes.columnLayout, {
      [classes[entityType]]: !!entityType && classes[entityType],
      [classes.columnLayout_2]: columns === 2,
      [classes.columnLayout_3]: columns === 3,
      [classes.reverse]: columns === 2 && reverse,
      [classes.equalColumnWidth]: equalColumnWidth,
    })}
  >
    {children}
  </div>
);

const Column = ({ entityType, entity, isEmbedded, blocks, hideBorder }) => {
  const renderComponent = useCallback(
    ({ component: SectionComponent, props, sectionProps }, index) => {
      const RenderComponent =
        sectionProps?.editMode?.enabled && !sectionProps?.editMode?.hasContent ? EmptySectionBlock : SectionComponent;
      return cloneElement(<RenderComponent />, {
        key: index,
        entityType,
        entity,
        entityId: entity?.id,
        isEmbedded,
        ...props,
        sectionProps: {
          enableDivider: index === 0,
          ...sectionProps,
        },
      });
    },
    [entity, entityType, isEmbedded],
  );

  return (
    <div className={classnames(classes.column, { [classes.hideBorder]: hideBorder })}>
      {blocks?.map(({ showSection = true, ...rest }, componentIndex) => {
        const sectionProps = rest?.sectionProps;

        if (!showSection) {
          return null;
        }

        if (sectionProps?.withoutWrapper) {
          return renderComponent(rest, componentIndex);
        }

        return (
          <div
            key={componentIndex}
            className={classnames(classes.section, {
              [classes.marginTopNone]: sectionProps?.marginTopNone,
              [classes.inline]: sectionProps?.inline,
              [classes.marginTopSmall]: sectionProps?.marginTopSmall,
              [classes.marginTopSixteen]: sectionProps?.marginTopSixteen,
              [classes[`theme-${sectionProps?.theme}`]]: !!sectionProps?.theme,
            })}
          >
            {renderComponent(rest, componentIndex)}
          </div>
        );
      })}
    </div>
  );
};

const moveNonEmptyGroupsToFront = groups =>
  groups.map(subGroup => {
    if (Array.isArray(subGroup)) {
      const nonEmptyGroups = subGroup.filter(item => item.length > 0);
      const emptyGroups = subGroup.filter(item => item.length === 0);
      return [...nonEmptyGroups.map(moveNonEmptyGroupsToFront), ...emptyGroups];
    }
    return subGroup;
  });

const Content = ({ entity, entityType, sections, isEmbedded = false, reverse = false, equalColumnWidth = true }) => {
  const { layout } = useDeviceTypeLayouts();
  const sortColumnBlocks = useCallback(
    blocks =>
      blocks?.sort(({ placement: placementA }, { placement: placementB }) => placementA?.order - placementB?.order),
    [],
  );

  const appendSection = useCallback(({ blocks = [], section, order, column }) => {
    if (section) {
      blocks.push({ ...section, placement: { order, column } });
    }

    return blocks;
  }, []);

  const createNewGroup = useCallback(
    ({ section, order, column = 1 }) => {
      const newGroup = Array.from(new Array(column), (_v, index) => {
        if (column - 1 === index) {
          return appendSection({ blocks: [], section, order, column });
        }

        return [];
      });

      return newGroup;
    },
    [appendSection],
  );

  const updateExistingGroup = useCallback(
    ({ lastGroup, section, order, column = 1 }) => {
      const lastGroupColumns = lastGroup?.length;
      const columns = column > lastGroupColumns ? column : lastGroupColumns;

      const existingGroup = Array.from(new Array(columns), (_v, index) => {
        if (index === column - 1) {
          return appendSection({ blocks: lastGroup[index], section, order, column });
        }

        return lastGroup[index];
      });

      return existingGroup;
    },
    [appendSection],
  );

  const groupedSections = useMemo(() => {
    const { grouped } = sections?.reduce(
      (acc, section) => {
        const { grouped: groups, previousColumn } = acc;
        const { order, column, hide } = section?.placement?.[layout] || {};
        const showSection = section?.showSection ?? true;

        if (hide || !showSection) {
          return {
            grouped: groups,
            previousColumn,
          };
        }

        if (groups?.length > 0 && ((previousColumn > 0 && column > 0) || previousColumn === column)) {
          const lastGroup = groups[groups.length - 1] || [];
          const updatedLastGroup = updateExistingGroup({ lastGroup, section, order, column });
          groups.splice(groups?.length - 1, 1, updatedLastGroup);
        } else {
          groups.push(createNewGroup({ section, order, column }));
        }

        return {
          grouped: groups,
          previousColumn: column,
        };
      },
      { grouped: [], previousColumn: null },
    );

    const sortedGroups = grouped?.map(group =>
      group?.reduce((acc, columns) => {
        const sortedColumns = sortColumnBlocks(columns);
        if (sortedColumns !== null && sortedColumns !== undefined) {
          acc.push(sortedColumns);
        }
        return acc;
      }, []),
    );

    return moveNonEmptyGroupsToFront(sortedGroups);
  }, [sections, layout, createNewGroup, updateExistingGroup, sortColumnBlocks]);

  return (
    <div className={classnames(classes.content, { [classes.content_embedded]: isEmbedded })}>
      {entity &&
        groupedSections?.map((columns, groupIndex) => (
          <ColumnLayout
            key={groupIndex}
            entityType={entityType}
            columns={columns.length}
            reverse={reverse}
            equalColumnWidth={equalColumnWidth}
          >
            {columns?.map((blocks, columnIndex) => (
              <Column
                key={columnIndex}
                blocks={blocks}
                entityType={entityType}
                entity={entity}
                isEmbedded={isEmbedded}
                hideBorder={!blocks?.length}
              />
            ))}
          </ColumnLayout>
        ))}
    </div>
  );
};

export default Content;

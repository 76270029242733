import React, { useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';
import { useQuery } from 'utils/react-query';
import LinkButton, { SecondaryButton } from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';
import Typography from 'components/uiLibrary/Typography';
import queries from 'containers/Accounts/queries';
import {
  useActiveProfileId,
  useAccessPendingProfileIds,
  useUserData,
  useActiveProfileSubscriptions,
} from 'utils/hooks/useAuthenticatedUser';
import { useSubscription } from 'utils/hooks/useSubscription';
import { REGISTRATION_STEPS } from 'containers/Accounts/constants';
import { SUBSCRIPTIONS_STATUSES } from 'containers/UserContainers/AccountSettingsContainer/constants';
import { useTranslation } from 'src/i18n';
import { TP, PREMIUM_NUDGE_POSITION, PREMIUM_NUDGE_VIEW_TYPES, POPUP_TYPES, THEMES } from 'constants/index';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';
import { useDialogs } from 'utils/hooks/useDialogs';
import { useNotificationPopup } from 'utils/hooks/useNotificationPopup';
import usePageContext from 'utils/hooks/usePageContext';
import { COMPONENTS, SUB_COMPONENTS } from 'components/Globals/Analytics/constants';

import classes from './PremiumPlanNudge.module.scss';

const PremiumPlanNudge = ({
  type = PREMIUM_NUDGE_VIEW_TYPES.DEFAULT,
  description,
  isHidden,
  placement,
  styles,
  hideDescriptionIcon = false,
  allowComparePlans,
  nudgeSourceType = '',
  theme,
  trackingData = {},
  skipTracking = false,
  disableStartSubscription = false,
  triggerStartFreeTrial = false,
  onCallback,
}) => {
  const { t } = useTranslation('NS_EDIT_MODE_SUBSCRIPTION_NUDGE');
  const userData = useUserData();
  const { isMobile } = useDeviceTypeLayouts();
  const activeProfileId = useActiveProfileId();
  const profileIdsPendingAccess = useAccessPendingProfileIds();
  const { permissions } = usePageContext();
  const subscription = useSubscription();
  const { setSubscriptionNudgesPopup, setAdminPopUp } = useNotificationPopup();
  const isChurned = subscription?.status === SUBSCRIPTIONS_STATUSES.cancelled;
  const { setIsEditDisclaimerModal, setIsAlreadyClaimedModal } = useDialogs();
  const { data: registrations } = useQuery(
    queries.getRegistrations({ userId: userData?.id, queryConfig: { enabled: !!userData?.id } }),
  );
  const isTrialExhausted = userData?.trialExhausted;
  const activeRegistration = registrations?.data?.find(reg => reg?.entities?.profile?.id === activeProfileId);
  const { status, step } = activeRegistration || {};
  const { has_accepted_disclaimer, has_seen_disclaimer, has_confirmed_disclaimer } = status || {};
  const shouldShowEditDisclaimerModal =
    step >= REGISTRATION_STEPS.subscriptionForm && !has_accepted_disclaimer && !has_seen_disclaimer;

  const isProfilePendingAccess = profileIdsPendingAccess?.includes(activeProfileId);
  const shouldShowAlreadyClaimedModal = has_accepted_disclaimer && isProfilePendingAccess && has_confirmed_disclaimer;

  const shouldShowDisclaimerAccepted = has_accepted_disclaimer && !has_confirmed_disclaimer;
  const handleModals = useCallback(() => {
    if (shouldShowEditDisclaimerModal) {
      setIsEditDisclaimerModal(true);
      return true;
    }

    if (shouldShowAlreadyClaimedModal) {
      setIsAlreadyClaimedModal(true);
      return true;
    }

    if (shouldShowDisclaimerAccepted) {
      setIsEditDisclaimerModal(true);
      return true;
    }

    if (permissions?.isAdmin) {
      setAdminPopUp({ isOpen: true });
      return true;
    }

    return false;
  }, [
    permissions?.isAdmin,
    setAdminPopUp,
    setIsAlreadyClaimedModal,
    setIsEditDisclaimerModal,
    shouldShowAlreadyClaimedModal,
    shouldShowDisclaimerAccepted,
    shouldShowEditDisclaimerModal,
  ]);

  const onStartFreeTrial = useCallback(() => {
    if (disableStartSubscription) {
      return;
    }

    if (onCallback) {
      onCallback();
    }

    if (handleModals()) {
      return;
    }

    let popupType;
    if (isChurned) {
      popupType = POPUP_TYPES.CHURNED;
    } else if (isTrialExhausted) {
      popupType = POPUP_TYPES.TRIAL;
    } else {
      popupType = POPUP_TYPES.UPGRADE;
    }

    setSubscriptionNudgesPopup({
      isOpen: true,
      popupType,
      source: nudgeSourceType,
      isCompare: false,
    });
  }, [
    disableStartSubscription,
    handleModals,
    isChurned,
    isTrialExhausted,
    nudgeSourceType,
    onCallback,
    setSubscriptionNudgesPopup,
  ]);

  const onComparePlans = () => {
    if (handleModals()) {
      return;
    }

    let popupType;
    if (isChurned) {
      popupType = POPUP_TYPES.CHURNED;
    } else if (isTrialExhausted) {
      popupType = POPUP_TYPES.TRIAL;
    } else {
      popupType = POPUP_TYPES.UPGRADE;
    }

    setSubscriptionNudgesPopup({
      isOpen: true,
      popupType,
      source: nudgeSourceType,
      isCompare: true,
    });
  };

  useEffect(() => {
    if (triggerStartFreeTrial) {
      onStartFreeTrial();
    }
  }, [onStartFreeTrial, triggerStartFreeTrial]);

  const { planLabel, subComponent } = useMemo(() => {
    if (isChurned) {
      return {
        planLabel: t('CL_REACTIVATE_TO_PREMIUM'),
        subComponent: SUB_COMPONENTS.REACTIVATE_SUBSCRIPTION,
      };
    }
    if (!isTrialExhausted) {
      return {
        planLabel: t(`${TP}.PAYMENTS_TITLE_START_TRIAL`),
        subComponent: SUB_COMPONENTS.START_FREE_TRIAL,
      };
    }

    return {
      planLabel: t('CL_UPGRADE_TO_PREMIUM'),
      subComponent: SUB_COMPONENTS.UPGRADE_TO_PRO,
    };
  }, [isChurned, isTrialExhausted, t]);

  return (
    <div
      className={classnames(classes[type], {
        [classes[`${placement}`]]: placement === PREMIUM_NUDGE_POSITION.AFTER,
        [styles?.root]: styles?.root,
      })}
    >
      <LinkButton
        variant="text"
        rightIcon={
          <SpriteIcon
            icon="chevron_right"
            size={16}
            className={classnames(classes.rightIcon, {
              [classes.rightIcon__themeDark]: theme === THEMES.DARK && isMobile,
            })}
          />
        }
        onClick={onStartFreeTrial}
        disableUnderline
        preventDefault
        stopPropagation
        trackingData={{
          ...trackingData,
          component: COMPONENTS.SUBSCRIPTION_NUDGE,
          subComponent,
        }}
        skipTracking={skipTracking}
      >
        <Typography weight="medium" size="10" color="white" className={classes.premiumNudge}>
          {t(`${TP}.FN_PROFILE_SUBSCRIPTION_TYPE_PREMIUM`)}
        </Typography>
        <Typography weight="medium" size="10" color={theme === THEMES.DARK && isMobile ? 'white' : 'inherit'}>
          {planLabel}
        </Typography>
      </LinkButton>
      {allowComparePlans && (
        <div className={classes.comparePlanContainer}>
          <div className={classes.divider} />
          <LinkButton
            variant="text"
            rightIcon={<SpriteIcon icon="chevron_right" size={16} className={classes.rightIcon} />}
            onClick={onComparePlans}
            styles={{ root: classes.comparePlans }}
            disableUnderline
            preventDefault
            stopPropagation
            trackingData={{
              ...trackingData,
              component: COMPONENTS.SUBSCRIPTION_NUDGE,
              subComponent: SUB_COMPONENTS.COMPARE_PLANS_CTA,
            }}
          >
            <Typography weight="medium" size="10" color="link">
              {t(`${TP}.FN_COMPARE_PLANS`)}
            </Typography>
          </LinkButton>
        </div>
      )}
      {type !== PREMIUM_NUDGE_VIEW_TYPES.CONCISE && (
        <div className={classes.fullView}>
          <Typography variant="p" size="14" className={classes.description}>
            {!hideDescriptionIcon && <SpriteIcon icon="hidden_eye" size={16} className={classes.hiddenEye} />}
            {description || t(`${TP}.SECTION_NOT_VISIBLE`)}
          </Typography>
          <SecondaryButton
            size={isMobile ? 'small' : 'medium'}
            {...(isHidden && { leftIcon: <SpriteIcon icon="hidden_eye" size={16} /> })}
            onClick={onStartFreeTrial}
            trackingData={{
              ...trackingData,
              component: COMPONENTS.SUBSCRIPTION_NUDGE,
              subComponent: isHidden ? SUB_COMPONENTS.SHOW_ON_PROFILE : SUB_COMPONENTS.HIDE_FROM_PROFILE,
            }}
          >
            {isHidden ? t(`${TP}.HIDE_FROM_PROFILE`) : t(`${TP}.SHOW_ON_PROFILE`)}
          </SecondaryButton>
        </div>
      )}
    </div>
  );
};

export default PremiumPlanNudge;

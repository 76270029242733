import React from 'react';

import classnames from 'classnames';

import Typography from 'components/uiLibrary/Typography';
import { PREMIUM_NUDGE_POSITION, THEMES } from 'constants/index';
import useDeviceTypeLayouts from 'utils/hooks/useDeviceTypeLayouts';

import PremiumPlanNudge from '../Layout/PremiumPlanNudge';
import { SectionHeading } from '../SectionBlock';
import EmptyPlaceholder from '../Layout/EmptyPlaceholder';

import classes from './EmptySectionBlock.module.scss';

const EmptySectionBlock = ({ ...props }) => {
  const { title, emptySections, description, premiumSection, styles, theme } = props?.sectionProps;
  const { isMobile } = useDeviceTypeLayouts();

  return (
    <>
      {premiumSection?.enabled && premiumSection?.placement === PREMIUM_NUDGE_POSITION.TOP && (
        <PremiumPlanNudge {...premiumSection} theme={theme} />
      )}
      <div
        className={classnames(classes.emptySectionBlock, {
          [styles?.root]: !!styles?.root,
        })}
      >
        {title && (
          <div className={classes.header}>
            <div className={classnames(classes.leftBoxIcon, { [classes.leftBoxIcon_dark]: theme === THEMES.DARK })}>
              <SectionHeading title={title} styles={styles} theme={theme} isMobile={isMobile} />
            </div>
            {description && (
              <Typography size="12" color="secondary" className={classes.description}>
                {description}
              </Typography>
            )}
          </div>
        )}
        {premiumSection?.enabled && premiumSection?.placement !== PREMIUM_NUDGE_POSITION.TOP && (
          <PremiumPlanNudge {...premiumSection} theme={theme} />
        )}
        <EmptyPlaceholder sections={emptySections} theme={theme} />
      </div>
    </>
  );
};

export default EmptySectionBlock;

import React from 'react';
import classnames from 'classnames';

import LinkButton from 'components/uiLibrary/LinkButton';
import SpriteIcon from 'components/uiLibrary/SpriteIcon';

import { EMPTY_PLACEHOLDER_TYPES, THEMES } from 'constants/index';

import classes from './EmptyPlaceholder.module.scss';

const EmptyPlaceholder = ({ sections, theme, styles }) => {
  if (!sections?.length) {
    return null;
  }

  return (
    <div
      className={classnames(classes.root, {
        [styles?.root]: !!styles?.root,
      })}
    >
      {sections.map((section, index) => (
        <div key={index} className={classes.sectionContainer}>
          {section.map((action, pointer) => (
            <LinkButton
              key={`${action.title}-${pointer}`}
              variant="text"
              styles={{
                root: classnames(classes[action?.type || EMPTY_PLACEHOLDER_TYPES.BUTTON], {
                  [classes.isDarkTheme]: theme === THEMES.DARK,
                }),
              }}
              {...(action.onClick && { onClick: action.onClick, stopPropagation: true, preventDefault: true })}
              {...(action.linkProps && { ...action.linkProps, isLink: true })}
              leftIcon={action.icon || <SpriteIcon icon="add_circle" size={16} />}
              trackingData={action?.trackingData}
              skipTracking={action?.skipTracking}
            >
              {action?.title}
            </LinkButton>
          ))}
        </div>
      ))}
    </div>
  );
};

export default EmptyPlaceholder;

import React, { createContext, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';

const initialData = {
  isProAccount: false,
  confirmProAccount: () => {},
  inValidAction: false,
  hasPermissions: { isProAccount: false },
  setInValidAction: () => {},
  confirmSpecialPermission: () => {},
  confirmSpecialPermissionWithSource: () => {},
};

const PropsDrillProviderContext = createContext(initialData);

PropsDrillProvider.propTypes = {
  entity: PropTypes.object,
  type: PropTypes.string,
};

function PropsDrillProvider(props) {
  const dataValue = useMemo(() => {
    const {
      isProAccount,
      confirmProAccount,
      inValidAction,
      setInValidAction,
      refetchNotifications,
      refetchCheckList,
      hasPermissions,
      confirmSpecialPermission,
      confirmSpecialPermissionWithSource,
    } = props;

    return {
      isProAccount,
      confirmProAccount,
      inValidAction,
      setInValidAction,
      refetchNotifications,
      refetchCheckList,
      hasPermissions,
      confirmSpecialPermission,
      confirmSpecialPermissionWithSource,
    };
  }, [props]);
  return <PropsDrillProviderContext.Provider value={dataValue} {...props} />;
}

export const usePropsDrillProvider = () => useContext(PropsDrillProviderContext);

export default PropsDrillProvider;
